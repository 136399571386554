import qs from "qs";

import {
  makePostRequest,
  makeGetRequest,
  makePutRequest,
  makeDeleteRequest,
} from "./axiosBase";

export const userList = async (limit, page, search) => {
  try {
    const params = {
      page,
      limit,
      search,
    };
    let res = await makeGetRequest("/admin/user", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const params = {};
    let res = await makeGetRequest("/admin/user/all", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const blockUser = async (userId) => {
  try {
    const data = {
      userId,
    };
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/user/block",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const config = { skipAuth: false };

    let res = await makeDeleteRequest("/admin/user", { userId }, config, {
      crossDomain: true,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const sendLoginEmail = async (userId) => {
  try {
    const data = {
      userId,
    };
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/user/sendLoginMail",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addUser = async (name, email, companyId, priceRangeId) => {
  try {
    const data = {
      name,
      email,
      companyId,
      priceRangeId,
    };
    const config = { skipAuth: false };
    let res = await makePostRequest("/admin/user", qs.stringify(data), config, {
      crossDomain: true,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const createSpecialUser = async (
  name,
  email,
  companyId,
  priceRangeId
) => {
  try {
    const data = {
      name,
      email,
      companyId,
      priceRangeId,
    };
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/user/addSpecialUser",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const specialUserList = async (limit, page, search) => {
  try {
    const params = {
      page,
      limit,
      search,
    };
    let res = await makeGetRequest("/admin/user/special", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const userDetail = async (userId, limit, page, search) => {
  try {
    const params = {
      userId,
      page,
      limit,
      search,
    };
    let res = await makeGetRequest("/admin/user/detail", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addAddress = async (data) => {
  try {
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/user/address",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const editAddress = async (data) => {
  try {
    const config = { skipAuth: false };
    let res = await makePutRequest(
      "/admin/user/address",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const editDetail = async (data) => {
  try {
    const config = { skipAuth: false };
    let res = await makePutRequest(
      "/admin/user/detail",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};
